import Timer from 'easytimer.js';
import { breaks } from './config';

const audio = new Audio();
const timer = new Timer();
audio.src = 'http://live.radiotaj.com:9484/;stream.mp3';
let index = 0;
let playing = false;
let wasAudioPlaying = false;

const title = document.getElementsByClassName('title')[0];
const audioPlayer = document.getElementsByClassName('audioPlayer')[0];
const audioFooter = document.getElementsByClassName('audioFooter')[0];
const videoPlayer = document.getElementsByClassName('videoPlayer')[0];
const videoFooter = document.getElementsByClassName('videoFooter')[0];

const play = document.getElementById('play-button');
const playIcon = document.getElementById('play-icon');
const shareButton = document.getElementById('share-button');
const share = document.getElementById('share');
const countdown = document.getElementById('countdown');
const timerBtn = document.getElementById('timer-button');

audioFooter.addEventListener('click', switchToVideo);
videoFooter.addEventListener('click', switchToAudio);

timer.addEventListener('secondsUpdated', updateHtml);
timer.addEventListener('targetAchieved', () => {
  toggleMusic();
  countdown.innerHTML = '';
});

const handleShare = () =>
  share.classList[0] === 'active'
    ? share.classList.remove('active')
    : share.classList.add('active');

play.addEventListener('click', () => {
  toggleMusic();
});

timerBtn.addEventListener('click', () => {
  let target = handleIndex();
  timer.stop();
  timer.start({
    startValues: { seconds: target },
    countdown: true,
  });
  if (!playing) {
    timer.pause();
  }
  if (target) {
    updateHtml();
  } else {
    countdown.innerHTML = '';
  }
});

shareButton.addEventListener('click', handleShare);
share.addEventListener('click', handleShare);
share.children[0].addEventListener('click', e => e.stopPropagation());

function handleIndex() {
  const len = breaks.length;
  let total;
  if (index < len) {
    total =
      breaks[index] +
      parseInt(parseInt(timer.getTotalTimeValues().seconds) / 60);
    index++;
  }
  if (!total) {
    index = 0;
    // total = breaks[0];
    timer.reset();
  }

  return total * 60;
}

function switchToAudio() {
  if (wasAudioPlaying) {
    if (timer.getTotalTimeValues().seconds !== 0) {
      timer.start();
      // timerBtn.click();
    }
    playIcon.classList.remove('mdi-play');
    playIcon.classList.add('mdi-pause');
    audio.play();
  }
  audioPlayer.style.display = 'block';
  audioFooter.style.display = 'block';
  videoPlayer.style.display = 'none';
  videoFooter.style.display = 'none';
}

function switchToVideo() {
  const track = title.innerText;

  if (!track) {
    return;
  }

  const query = `?part=id&maxResults=1&q=${track}&type=video&key=AIzaSyB2xoZHzYNKzCGo03eoaYrIN3cUvZs1maI`;
  /* eslint-disable */
  fetch('https://www.googleapis.com/youtube/v3/search' + query)
  .then((resp) => resp.json())
  .then((result) => {
    result.items.forEach(item => {
      const videoId = item.id.videoId;
      const output = '<iframe style="width:100%; max-width:450px; border:0;" src=\"//www.youtube.com/embed/'+ videoId +'\"></iframe>';
      audioPlayer.style.display = 'none';
      audioFooter.style.display = 'none';

      videoPlayer.innerHTML = output;
      videoPlayer.style.display = 'block';
      videoFooter.style.display = 'block';
      
      if (playing) {
        timer.pause();
        playIcon.classList.add('mdi-play');
        playIcon.classList.remove('mdi-pause');
        audio.pause();
      }
    });
  })
  .catch(() => {
  });
  /* eslint-enable */
}

function updateHtml() {
  countdown.innerHTML = timer
    .getTimeValues()
    .toString(['hours', 'minutes', 'seconds']);
}

function toggleMusic() {
  if (playing) {
    wasAudioPlaying = false;
    timer.pause();
    playIcon.classList.add('mdi-play');
    playIcon.classList.remove('mdi-pause');
    audio.pause();
  } else {
    wasAudioPlaying = true;
    if (timer.getTotalTimeValues().seconds !== 0) {
      timer.start();
      // timerBtn.click();
    }
    playIcon.classList.remove('mdi-play');
    playIcon.classList.add('mdi-pause');
    audio.play();
  }
  playing = !playing;
}
